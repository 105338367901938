<template>
  <div>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card :loading="loading">
          <v-card-title>
            <span>
              {{ $t('Setting') }}
            </span>
          
          </v-card-title>
          <v-divider class="mb-2"></v-divider>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                lg="4"
                class="pt-0 text-center"
              >
                <img
                  :src="shopData.shop_image"
                  class="set-block"
                  alt=""
                />
              </v-col>
              <v-col
                cols="12"
                lg="8"
                class="pt-0"
              >
                <v-text-field
                  v-model.trim="shopData.shop_id"

                  outlined
                  dense
                  :label="$t('shop_code')"
                  readonly
                ></v-text-field>
                <v-text-field
                  v-model.trim="shopData.shop_name"
                  outlined
                  dense
                  :label="$t('shop_name')"
                  readonly
                ></v-text-field>
                <v-text-field
                  v-model.trim="shopData.shop_nature"
                  outlined
                  dense
                  :label="$t('nature_service')"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                lg="6"
                class="d-flex"
              >
                <v-select
                  v-model.trim="bankSelect"
                  :items="bankData"
                  :label="$t('bank_icon')"
                  dense
                  hide-details="auto"
                  outlined
                  item-text="bank_name"
                  return-object
                  item-value="bank_icon"
                >
                </v-select>
                <i
                  class="ml-1 mt-1"
                  :class="bankSelect.bank_icon"
                ></i>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <v-text-field
                  v-model.trim="shopData.recipient_number"
                  outlined
                  dense
                  hide-details="auto"
                  :label="$t('account_number')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <v-text-field
                  v-model.trim="bankSelect.bank_name"
                  outlined
                  hide-details="auto"
                  dense
                  :label="$t('bank')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <v-text-field
                  v-model.trim="shopData.recipient_name"
                  outlined
                  hide-details="auto"
                  dense
                  :label="$t('account_name')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <span class="error--text">
              {{ $t('costTransferToShop') }}
            </span>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              @click="isUserConfirm = true"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              :loading="loading"
              :disabled="loading"
              outlined
              @click="getShop"
            >
              {{ $t('default') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
      >
        <v-card :loading="settingLoading">
          <v-card-title>
            {{ $t('general_information') }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-checkbox
                  v-model="shopData.shop_clinicpro_hide_opd"
                  :false-value="1"
                  :true-value="0"
                  :label="$t('closeOPD')"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-checkbox
                  v-model="shopData.shop_clinicpro_hide_lab"
                  :false-value="1"
                  :true-value="0"
                  :label="$t('closeLabResult')"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-checkbox
                  v-model="shopData.shop_clinicpro_hide_order"
                  :false-value="1"
                  :true-value="0"
                  :label="$t('closeOrder')"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="d-flex"
              >
                <v-text-field
                  v-model="shopData.shop_crm_point"
                  dense
                  outlined
                  type="number"
                  class="me-2"
                  :label="$t('pointReceiveAfterReview')"
                >
                </v-text-field>
                <v-text-field
                  v-model.trim="shopData.shop_shipping_cost"
                  outlined
                  hide-details="auto"
                  dense
                  type="number"
                  :label="$t('shippoingCost')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="settingLoading"
              :disabled="settingLoading"
              @click="updateSettingShop"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              :loading="settingLoading"
              :disabled="settingLoading"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <UserConfirm
      v-model="isUserConfirm"
      @onSend="updateShop"
    />
  </div>
</template>

<script>
import { mdiDeleteOutline, mdiCloudUploadOutline } from '@mdi/js'
import useSetting from './useSetting'
import UserConfirm from '@/components/basicComponents/UserConfirm.vue'

export default {
  components: {
    UserConfirm,
  },
  setup() {
    return {
      ...useSetting(),
      icons: {
        mdiDeleteOutline, mdiCloudUploadOutline,
      },
    }
  },

}
</script>

<style lang="scss" scoped>

.set-block{
  border: 1px dashed #212121;
  border-radius: 10px;
  width:200px;
  background-color:#d6d6d6;
}

</style>
