import crmLine from '@/api/CRM-line/crmLine'
import bankicon from '@/api/WebsiteManageMent/bankicon'
import store from '@/store'
import { onMounted, ref } from '@vue/composition-api'

export default function useSystemSetting() {
  const loading = ref(false)
  const shopData = ref({})
  const bankData = ref([])
  const bankSelect = ref('')
  const shop_image = ref('')
  const blob_image = ref('')
  const shopDetail = ref('')
  const selectImage = ref(null)
  const { bankiconList } = bankicon
  const isUserConfirm = ref(false)
  const showBeforeAfter = ref(false)
  const settingLoading = ref(false)

  onMounted(() => {
    getShop()
  })

  const getShop = () => {
    settingLoading.value = true
    loading.value = true
    crmLine.costTransferGet().then(res => {
      getSettingShop(res)
      bankiconList().then(data => {
        bankData.value = data
        bankData.value.splice(0, 1)
        bankSelect.value = data.find(item => item.bank_icon == res.brand_icon)
        loading.value = false
      })
    })
  }

  const getSettingShop = data1 => {
    crmLine.settingShopGet().then(res => {
      shopData.value = { ...data1, ...res }
      settingLoading.value = false
    })
  }

  const updateSettingShop = () => {
    loading.value = true
    crmLine.settingShopUpdate(shopData.value).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      getShop()
      loading.value = false
    })
  }

  const updateShop = () => {
    loading.value = true
    crmLine
      .costTransfer({
        recipient_brand: bankSelect.value.bank_icon.replace('thbanks thbanks-', ''),
        recipient_name: shopData.value.recipient_name,
        recipient_number: shopData.value.recipient_number,
      })
      .then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        getShop()
        loading.value = false
      })
  }

  return {
    shopData,
    isUserConfirm,
    bankData,
    bankSelect,
    shop_image,
    showBeforeAfter,
    blob_image,
    loading,
    shopDetail,
    selectImage,
    getShop,
    updateShop,
    updateSettingShop,
    settingLoading,
  }
}
